
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import { dealsSidebarRoutes } from '@/router/sidebarRoutes/dealsRoutes'
import TmButton from '@/components/shared/TmButton.vue'
import { useDeals } from '@/compositions/deals/useDeals'

export default defineComponent({
  components: {
    TmButton,
    PageContentSidebarList,
    PageContentSidebarHr,
  },
  setup() {
    const { openTemplateModal } = useDeals()
    return {
      openTemplateModal,
      dealsSidebarRoutes,
    }
  },
})
