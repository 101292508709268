
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import DealsSidebar from '@/components/pages/deals/DealsSidebar.vue'

export default defineComponent({
  components: {
    DealsSidebar,
    TmButton,
  },
})
